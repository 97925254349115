import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-rochester-minnesota.png'
import image0 from "../../images/cities/scale-model-of-zumbro-west-park-in-rochester-minnesota.png"
import image1 from "../../images/cities/scale-model-of-plummer-house-in-rochester-minnesota.png"
import image2 from "../../images/cities/scale-model-of-history-center-of-olmsted-county-in-rochester-minnesota.png"
import image3 from "../../images/cities/scale-model-of-silver-lake-park-in-rochester-minnesota.png"
import image4 from "../../images/cities/scale-model-of-minnesota-children's-museum-in-rochester-minnesota.png"
import image5 from "../../images/cities/scale-model-of-gabriele's-travels-to-italy-in-rochester-minnesota.png"
import image6 from "../../images/cities/scale-model-of-glenn's-motorcoach-tours-in-rochester-minnesota.png"
import image7 from "../../images/cities/scale-model-of-plummer-house-park-in-rochester-minnesota.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Rochester'
            state='Minnesota'
            image={image}
            lat='44.0121221'
            lon='-92.4801989'
            attractions={ [{"name": "Zumbro West Park", "vicinity": "1910 1st St SW, Rochester", "types": ["park", "point_of_interest", "establishment"], "lat": 44.0223696, "lng": -92.4924595}, {"name": "Plummer House", "vicinity": "1091 Plummer Ln SW, Rochester", "types": ["point_of_interest", "establishment"], "lat": 44.0105371, "lng": -92.47960260000002}, {"name": "History Center of Olmsted County", "vicinity": "1195 W Circle Dr, Rochester", "types": ["museum", "point_of_interest", "establishment"], "lat": 44.0062338, "lng": -92.50990379999996}, {"name": "Silver Lake Park", "vicinity": "770 W Silver Lake Dr NE, Rochester", "types": ["park", "point_of_interest", "establishment"], "lat": 44.031929, "lng": -92.459026}, {"name": "Minnesota Children's Museum", "vicinity": "1643 North Broadway Street A, Rochester", "types": ["museum", "point_of_interest", "establishment"], "lat": 44.040178, "lng": -92.46271000000002}, {"name": "Gabriele's Travels To Italy", "vicinity": "3037 14th Ave NW, Rochester", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 44.055525, "lng": -92.48414300000002}, {"name": "Glenn's Motorcoach Tours", "vicinity": "5803 15th St NW, Rochester", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 44.0378006, "lng": -92.55447629999998}, {"name": "Plummer House Park", "vicinity": "1091 Plummer Ln SW, Rochester", "types": ["park", "point_of_interest", "establishment"], "lat": 44.0112766, "lng": -92.4780346}] }
            attractionImages={ {"Zumbro West Park":image0,"Plummer House":image1,"History Center of Olmsted County":image2,"Silver Lake Park":image3,"Minnesota Children's Museum":image4,"Gabriele's Travels To Italy":image5,"Glenn's Motorcoach Tours":image6,"Plummer House Park":image7,} }
       />);
  }
}